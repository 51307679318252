//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: 'EntityCount',
data(){
    return {
        users: this.$store.state.entitiesCount.users,
        groups: this.$store.state.entitiesCount.groups,
        events: this.$store.state.entitiesCount.events,
    }
},
}
