//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
import moment from 'moment'
export default {
    name: "UpcomingEvent",
    components: { EmptyMessage},
    data () {
        return {
            user_id: this.$store.state.user.id,
            upcoming_events: null,
            isEmpty: false
        }
    },
    methods: {
        fetchUpComingEvents(id){
           this.$store.dispatch('event/fetchEventList',id)
            .then((r) =>{
                if(r.status == 'success'){
                    this.upcoming_events = r.eventlist.slice(0,4)
                    this.pagination = r.pagination
                }else{
                    this.isEmpty = true;
                }
            })
        }
    },
    filters: {
        month: function (date_start,date_end) {
            if( moment(new Date(date_start)).format('MMM') == moment(new Date(date_end)).format('MMM')){
                return moment(new Date(date_start)).format('MMM')
            }else{
                return moment(new Date(date_start)).format('MMM') +' - '+ moment(new Date(date_end)).format('MMM')

            }
        },
        days: function (date_start,date_end) {
            if( moment(new Date(date_start)).format('DD') == moment(new Date(date_end)).format('DD')){
                return moment(new Date(date_start)).format('DD')
            }else{
                return moment(new Date(date_start)).format('DD') +' - '+ moment(new Date(date_end)).format('DD')

            }
        },
    },
    created() {
        this.fetchUpComingEvents(this.$store.state.user.id);
    }
}
