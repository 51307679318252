//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EntityCount from '@/components/Sidebars/EntityCount'
import PostContainer from '@/components/Posts/PostContainer'
import EventUpcomings from '@/components/Sidebars/UpcomingEvent'
import SuggestedGroups from '@/components/Sidebars/SuggestedGroups'
import { mapGetters } from 'vuex'
export default {
  components: {
    PostContainer,
    EventUpcomings,
    SuggestedGroups,
    EntityCount,
    LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
  },
  metaInfo(){
    return{
      title: 'Golfbean',
      titleTemplate: ''
    }
  },
  data() {
    return {
      showMediaUploader: false
    }
  },
  computed: {
    ...mapGetters({
      upcoming_events: "event/allUpcomingEvents"
    })
  },
  methods: {
    hideUploader() {
      this.showMediaUploader = false;
    }
  },
}
