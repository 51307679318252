//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
import EntityThumbnail from '@/components/EntityThumbnail.vue'
export default {
    name: "SuggestedGroups",
    components: {EmptyMessage,EntityThumbnail},
    data(){
        return {
            user_id: this.$store.state.user.id,
            isJoining: false,
            suggested_groups: [],
            currentPage: 1,
            q:'',
            groupCoverPhoto: ''
        }
    },
    methods: {
        fetchGroups(){
            this.isLoading = true;
            this.$http({
                url: 'entities/search?eid=1&page=' + this.currentPage + '&q=' + this.q,
                method: 'GET'
            }).then((response) => {
                if (response.data['status'] == 'entities_retrieved') {
                    this.isLoading = false;
                     response.data['body']['entities'].map((group) => {
                        if(group.user_id != this.$store.state.user.id){
                            this.suggested_groups.push(group)
                        }
                    })
                    this.suggested_groups = this.suggested_groups.splice(0,4)
                    this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
                    this.isEmpty = false;
                } else if (response.data['status'] == 'empty_result') {
                    this.isLoading = false;
                    this.isEmpty = true;
                }
            })
        },
        join(id) {
            this.isJoining = true;
            this.$http({
                url: 'entities/' + id + '/join',
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == 'entity_joined') {
                    this.isJoining = false;
                    this.$q.notify({
                        message: "You have successfully joined group.",
                        color: 'positive'
                    });
                    this.fetchSuggestedGroupList();                
                } else {
                    this.isJoining = false;
                    this.$q.notify({
                        message: 'There has been an error while trying to join the group. Please try again later.',
                        color: 'negative'
                    })
                }
            })
        },

        fetchThumbnails(id){
            this.payload = {
                id:id
            }
            this.$store.dispatch('group/getCoverPhoto',this.payload)
            .then((r) => {
                r.status == 'success' ? this.groupCoverPhoto = r.image : ''
            })
        },
    },
    created(){
        this.fetchGroups();
    }

}
